import axios from "axios";
import React, { useEffect } from "react";
import {v4 as uuid } from "uuid";

export default function Voice ({audioUrl, transcriptUrl, aiTranscript}) {

    const id = uuid();

    const [transcript, setTranscript] = React.useState([]);

    useEffect(() => {
        axios(transcriptUrl)
        .then(res => {
            const raw = res.data?.results?.items;
            const final = [[]];

            raw.forEach((item, index) => {
                if(index !== 0) {
                    if(
                        raw[index - 1]?.speaker_label !== item?.speaker_label && 
                        item.type !== "punctuation"
                    ) final.unshift([]);
                    
                }
                final[0].push(item);
            });

            final.reverse()

            setTranscript(final);
        })
    }, [])
    return (
        <React.Fragment>
<div className="card">
    <div className="card-body">
        <h5>Audio recording <a href={audioUrl} className="btn btn-sm btn-danger" download ><i className="fas fa-download"/> Download recording</a> </h5>
        <audio id={id} controls>
            <source src={audioUrl}/>
        </audio>
        <hr/>
        <h5>Transcript</h5>
            
            {transcript.length === 0 ? <div className="alert alert-warning">
                Transcript not available
            </div> : aiTranscript ? aiTranscript :
            <ul className="list-group">
                {transcript.map(sentence => {
                    return (
                        <li className="list-group-item">
                            <span className="underline-hover" style={{display: "inline-block", width: "200px"}} onClick={()=> {
                                    const audio = document.getElementById(id);
                                    audio.currentTime = sentence[0]?.start_time
                            }}>
                                ({sentence[0]?.start_time} sec) <b>Speaker {Number(sentence[0]?.speaker_label?.split("_")[1])+1}: </b>
                            </span>
                            {sentence.map(word => {
                                return <span className="underline-hover" onClick={()=> {
                                    const audio = document.getElementById(id);
                                    audio.currentTime = word?.start_time
                                }}>
                                    {word.alternatives[0].content+" "}
                                    {/* document.getElementById("myVideo") */}
                                </span>
                            })}
                        </li>
                    );
                })}
            </ul>}
    </div>
</div>
            

        </React.Fragment>
    )
}